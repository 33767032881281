var render = function () {
  var _vm$newReportFormData;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-10 offset-lg-1"
  }, [_c('sygni-container-title', {
    staticClass: "mb-5"
  }, [_vm._v("Krok 3 - wgraj pliki")]), _c('div', {
    staticClass: "reporting-regulatory-card"
  }, [!_vm.isLoadingReport ? [_vm.isOpen && _vm.reportStatus !== _vm.reportStatuses.DRAFT ? _c('div', {
    staticClass: "funds"
  }, [_vm._l((_vm$newReportFormData = _vm.newReportFormData) === null || _vm$newReportFormData === void 0 ? void 0 : _vm$newReportFormData.selectedASI, function (fund, index) {
    var _vm$getFundName;

    return [_c('sygni-card', {
      key: fund.id,
      staticClass: "inner-card sygni-card--borderless",
      class: _vm.reportStatus === _vm.reportStatuses.COMPLETED || _vm.reportStatus === _vm.reportStatuses.GENERATED ? 'inner-card--confirmed' : ''
    }, [_vm.reportStatus === _vm.reportStatuses.NEW && index === 0 ? _c('sygni-info-box', [_c('p', [_vm._v("W celu weryfikacji poprawności wprowadzonych danych przez naszych ekspertów, prosimy o dodanie informacji (Bilans, ZOiS), na podstawie których zostanie uzupełniony formularz z danymi oraz przypisanie kategorii zestawienia.")])]) : _vm._e(), _c('div', {
      staticClass: "inner-card__row inner-card__row--header"
    }, [_c('div', {
      staticClass: "image"
    }, [_vm.getFundLogo(fund === null || fund === void 0 ? void 0 : fund.logo) ? _c('img', {
      attrs: {
        "src": _vm.getFundLogo(fund === null || fund === void 0 ? void 0 : fund.logo)
      }
    }) : _c('div', {
      staticClass: "char"
    }, [_vm._v(" " + _vm._s((_vm$getFundName = _vm.getFundName(fund)) === null || _vm$getFundName === void 0 ? void 0 : _vm$getFundName.slice(0, 1)) + " ")])]), _vm._v(" " + _vm._s(_vm.getFundName(fund)) + " ")]), _vm.reportStatus === _vm.reportStatuses.NEW ? _c('file-uploader', {
      attrs: {
        "supported-file-formats": _vm.supportedFileFormats,
        "uploadProgress": _vm.uploadProgress,
        "isDisabled": _vm.isDisabled
      },
      on: {
        "input": function input($event) {
          return _vm.addFile($event, fund === null || fund === void 0 ? void 0 : fund.id);
        }
      }
    }) : _vm._e(), _vm.reportStatus === _vm.reportStatuses.NEW ? _c('sygni-container-title', {
      staticClass: "uploaded-files-title"
    }, [_vm._v(" Uploaded files ")]) : _vm._e(), _c('reporting-regulatory-list', {
      key: _vm.reRenderTable,
      attrs: {
        "confirmed": false,
        "isDisabled": _vm.isDisabled,
        "items": _vm.getFileItems(fund),
        "report": _vm.selectedReport,
        "updateOnChange": false,
        "show-category-guide": false,
        "showPagination": false
      },
      on: {
        "setCategory": function setCategory($event) {
          return _vm.setCategory($event);
        },
        "deleteFile": function deleteFile($event) {
          return _vm.deleteFile(fund, $event);
        }
      }
    })], 1)];
  })], 2) : _vm._e(), !_vm.isOpen && _vm.reportStatus === _vm.reportStatuses.NEW ? _c('reg-rep-hidden-card') : _vm._e()] : _vm._e(), _vm.isLoadingReport ? [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)] : _vm._e()], 2)], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }