



















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';
import { Prop, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

@Component({
  components: { SygniSelect, SygniMultiSelect, SygniCheckbox, SygniContainerTitle, SygniInfoBox },
  computed: {
    ...mapState('regReporting', {
      fundData: (state: any) => state.fundData,
      funds: (state: any) => state.funds,
      outputReportData: (state: any) => state.outputReportData,
      newReportFormData: (state: any) => _.cloneDeep(state.newReportFormData),
    }),
  }
})
export default class ReportStep2 extends Vue {
  @Prop() isDisabled: boolean;
  fundData!: any;
  funds!: any;
  outputReportData!: any;
  newReportFormData!: any;

  formData: any = {
    selectedZASI: '',
  };

  get selectedAsiOptions() {
    return this.newReportFormData.selectedASI.map((el: any) => {
      return el.id;
    })
  }

  get zasiOptions() {
    return [
      {
        label: this.fundData.name,
        value: this.fundData.name
      }
    ];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isFund() {
    return this.activeUserData.context.context === 'fund';
  }

  get asiOptions() {
    if(this.isFund) {
      return [{ label: this.fundData.name, value: this.fundData.id }];
    }

    const excludedAifIds: string[] = this.excludedAifs.map((el: any) => el.id)

    return this.outputReportData.aif.map(((el: any) => {
      return { label: `${el.aifName} (${el?.aifNumber})`, value: el.id, disabled: excludedAifIds.includes(el.id) };
    })).sort((a: any) => a.disabled ? 0 : -1);
  }

  setSelectedASIs(options: any) {
    this.$store.commit('regReporting/setSelectedASIs', options);
    this.validateStep();
  }
  
  toggleReportData(optionName: string) {
    this.$store.commit('regReporting/toggleReportData', optionName);
  }

  get requiredAifs() {
    const reportYear = this.newReportFormData?.reportYear?.value

    return this.outputReportData?.aif?.filter((el: any) => {
      if (el?.dateOfDiscontinuationOfAifManagement) {
        if (moment(el.dateOfDiscontinuationOfAifManagement).year() < Number(reportYear)) {
          return false
        }
      }

      if (el?.aifManagementStartDate) {
        const startDate = moment(el?.aifManagementStartDate)

        if (Number(reportYear) === startDate?.year() && startDate?.quarter() === 4) {
          return false
        }
      }

      return true
    })
  }

  get excludedAifs() {
    const reportYear = this.newReportFormData?.reportYear?.value

    return this.outputReportData?.aif?.filter((el: any) => {
      if (el?.dateOfDiscontinuationOfAifManagement) {
        if (moment(el.dateOfDiscontinuationOfAifManagement).year() < Number(reportYear)) {
          return true
        }
      }

      if (el?.aifManagementStartDate) {
        const startDate = moment(el?.aifManagementStartDate)

        if (Number(reportYear) === startDate?.year() && startDate?.quarter() === 4) {
          return true
        }
      }

      return false
    })
  }

  validateStep() {
    if(this.isDisabled) return;

    if(this.newReportFormData.currentStep === 2) {
      const includesAllRequired = this.requiredAifs.map((el: any) => {
        return this.newReportFormData?.selectedASI?.find((asi: any) => asi.id === el.id) ? true : false
      })
      const excludesAllRequired = this.excludedAifs.map((el: any) => {
        return this.newReportFormData?.selectedASI?.find((asi: any) => asi.id === el.id) ? true : false
      })

      const invalidSelectedASIs = !this.newReportFormData.selectedASI.length
      const hasErrors = includesAllRequired?.includes(false) || excludesAllRequired?.includes(true)
  
      if (invalidSelectedASIs || hasErrors) {
        this.$emit('setNextStepBtnDisabled', true);
      } else {
        this.$emit('setNextStepBtnDisabled', false);
      }
    }
  }

  mounted() {
    this.formData.selectedZASI = this.fundData.name;
    if(this.isDisabled) return;

    if(this.isFund && !this.newReportFormData?.selectedASI?.length) {
      this.setSelectedASIs([this.fundData.id]);
    }
    this.validateStep();
  }

  @Watch('newReportFormData', { deep: true }) onFormDataChange() {
    this.validateStep();
  }

  @Watch('fundData') onFundDataChange() {
    if(this.isDisabled) return;

    this.formData.selectedZASI = this.fundData.name;
  }
}
