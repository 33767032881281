var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-8 offset-lg-2"
  }, [_c('sygni-container-title', [_vm._v("Krok 4 - Podsumowanie zakresu raportowania")]), _c('sygni-info-box', [_c('p', [_vm._v("Oto zakres raportowanych podmiotów. Wprowadź wymagane dane finansowe każdej z ASI (w przypadku WZASI będą to dane WZASI).")]), _c('p', [_vm._v("Po uzupełnieniu pełnych danych, wykonaj akcję \"" + _vm._s(_vm.isAdmin ? 'Generate' : 'Submit') + " Report\" - wygenerowane zostaną raporty, które będą weryfikowane.")]), _c('p', [_vm._v("Gotowe i sprawdzone raporty zostaną opublikowane najszybciej jak to możliwe.")])]), _c('div', {
    staticClass: "step__content"
  }, [_c('h2', [_vm._v("ZASI:")]), _c('h3', [_vm._v(_vm._s(_vm.outputReportData.aifmName))]), _c('p', [_vm._v("ID: "), _c('span', [_vm._v(_vm._s(_vm.outputReportData.aifmNumber))])]), _c('p', [_vm._v("Register date: "), _c('span', [_vm._v(_vm._s(_vm.outputReportData.regulatoryRegistrationDate ? _vm.getRegistrationDate(_vm.outputReportData.regulatoryRegistrationDate) : '-'))])]), _c('p', [_vm._v("Reporting start date: "), _c('span', [_vm._v(_vm._s(_vm.getStartReportDate(_vm.outputReportData.regulatoryRegistrationDate)))])]), _c('p', [_vm._v("Reporting end date: "), _c('span', [_vm._v(_vm._s(_vm.getEndReportDate(_vm.outputReportData.regulatoryDeRegistrationDate)))])]), _c('p', [_vm._v("Business termination date: "), _c('span', [_vm._v(_vm._s(_vm.outputReportData.regulatoryDeRegistrationDate ? _vm.getDate(_vm.outputReportData.regulatoryDeRegistrationDate) : '-'))])]), _c('div', {
    staticClass: "step__inner"
  }, [_c('h2', [_vm._v("ASIs:")]), _vm._l(_vm.newReportFormData.selectedASI, function (item, index) {
    return _c('div', {
      key: "".concat(index).concat(item),
      class: ['step__item', item.balanceData ? 'added' : '']
    }, [_c('div', {
      staticClass: "step__item-info"
    }, [_c('h3', [_vm._v(_vm._s(item.name))]), _c('p', [_vm._v("ID: "), _c('span', [_vm._v(_vm._s(item.aifNumber))])]), _c('p', [_vm._v("Register date: "), _c('span', [_vm._v(_vm._s(item.aifManagementStartDate ? _vm.getRegistrationDate(item.aifManagementStartDate) : '-'))])]), _c('p', [_vm._v("Reporting start date: "), _c('span', [_vm._v(_vm._s(_vm.getStartReportDate(item.aifManagementStartDate)))])]), _c('p', [_vm._v("Reporting end date: "), _c('span', [_vm._v(_vm._s(_vm.getEndReportDate(item.dateOfDiscontinuationOfAifManagement)))])]), _c('p', [_vm._v("Business termination date: "), _c('span', [_vm._v(_vm._s(item.dateOfDiscontinuationOfAifManagement ? _vm.getDate(item.dateOfDiscontinuationOfAifManagement) : '-'))])])]), _c('div', {
      staticClass: "step__item-actions"
    }, [item.reportData && item.balanceData && item.validated ? _c('div', {
      staticClass: "step__notification"
    }, [_c('p', {
      staticClass: "step__notification-text"
    }, [_vm._v("Bilans dodany")]), _c('img', {
      staticClass: "step__notification-icon",
      attrs: {
        "src": _vm._f("getIcon")('ACCEPT'),
        "alt": ""
      }
    })]) : _vm._e(), item.reportData && item.balanceData ? _c('sygni-rounded-button', {
      staticClass: "outline gn-secondary",
      on: {
        "click": function click($event) {
          return _vm.openBalanceReport(item.name);
        }
      }
    }, [_vm._v(_vm._s(_vm.isDisabled ? 'Zobacz dane' : 'Edytuj dane'))]) : _vm._e(), item.reportData && !item.balanceData ? _c('sygni-rounded-button', {
      staticClass: "filled gn-primary",
      on: {
        "click": function click($event) {
          return _vm.openBalanceReport(item.name);
        }
      }
    }, [_vm._v("Wprowadź dane")]) : _vm._e()], 1)]);
  })], 2)])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }