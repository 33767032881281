















































































































































































































































































import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniPlainInput from "@/components/inputs/SygniPlainInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import FileUploader from '@/components/FileUploader.vue';
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';
import { BTable } from 'bootstrap-vue';
import _ from 'lodash';
import { all, create } from 'mathjs';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CreateRegRepDTO, OutputReportData, UploadFileToReportDTO } from '@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types';
import { mapState } from 'vuex';
import moment from 'moment';
import { SelectOption } from '@/store/types';
const math = create(all);

@Component({
  components: { SygniSelect, FileUploader, SygniMultiSelect, SygniCheckbox, BTable, SygniPlainInput, SygniInput, SygniRoundedButton, SygniContainerTitle, SygniInfoBox, SygniLinkButton },
  computed: {
    ...mapState('regReporting', {
      outputReportData: (state: any) => state.outputReportData,
    })
  }
})
export default class ReportBalance extends Vue {
  @Prop({ default: null }) reportId: string;
  @Prop() groupedReports: any;
  @Prop() isDisabled: boolean;


  readonly supportedFileFormats: Array<string> = ['xml'];
  
  outputReportData!: OutputReportData;
  additionalsError: boolean = false;
  reRenderDetails: number = 0;
  reRenderTotals: number = 0
  xml: string = null
  math: any = math
    
  get tableFields() {
    return [
      { key: 'code', sortable: false, label: 'Kod' },
      { key: 'segment', sortable: false, label: 'Segment' },
      { key: 'amount', sortable: false, class: 'no-padding centered text-left', label: 'Enter amount' },
      // { key: 'amount', sortable: false, class: 'no-padding centered text-right', label: this.getEndReportDate(this.outputReportData.regulatoryDeRegistrationDate) },
    ]
  }

  get tableAdditionalFields() {
    return [
      { key: 'segment', sortable: false, label: 'Segment' },
      { key: 'amount', sortable: false, class: 'no-padding centered text-left', label: 'Enter amount' },
      // { key: 'amount', sortable: false, class: 'no-padding centered text-right', label: this.getEndReportDate(this.outputReportData.regulatoryDeRegistrationDate) },
    ]
  }

  get lastCompletedReportBalanceFromPreviousYear() {
    const year: number = Number(this.newReportFormData?.reportYear?.value) - 1
    const completedReports: any = this.groupedReports[year]?.filter((report: any) => report.status?.toLowerCase() === 'completed')

    if (!completedReports?.length) {
      return null
    }

    const foundedReport: any = completedReports?.sort((a: any, b: any) => b?.correctionNumber - a?.correctionNumber)[0]

    const selectedAsi = JSON.parse(foundedReport?.data)?.selectedASI?.find((asi: any) => asi.id === this.selectedASI?.id)

    return selectedAsi?.balanceData
  }

  countryOptions: any[] = [
    { label: 'Europa (EOG)', value: 'Europa (EOG)' },
    { label: 'Europa (poza EOG)', value: 'Europa (poza EOG)' },
    { label: 'Afryka', value: 'Afryka' },
    { label: 'Ameryka Północna', value: 'Ameryka Północna' },
    { label: 'Ameryka Południowa', value: 'Ameryka Południowa' },
    { label: 'Bliski Wschód', value: 'Bliski Wschód' },
    { label: 'Azja i Pacyfik (inne niż Bliski Wschód)', value: 'Azja i Pacyfik (inne niż Bliski Wschód)' },
    { label: 'Wiele regionów', value: 'Wiele regionów' },
  ]

  assetTypeOptionsEnabledFor: any[] = [
    'A. IV. 3. a. ab',
    'A. IV. 3. a. ad',
    'A. IV. 3. b. ab',
    'A. IV. 3. b. ad',
    'A. IV. 3. c. ab',
    'A. IV. 3. c. ad',
    'B. III. 1. a. ab',
    'B. III. 1. a. ad',
    'B. III. 1. b. ab',
    'B. III. 1. b. ad',
  ]

  marketOptions: Array<any> = [
    { label: 'Rynek giełdowy w Warszawie', value: 'Rynek giełdowy' },
    { label: 'NewConnect', value: 'NewConnect' },
    { label: 'Nieregulowany rynek pozagiełdowy OTC', value: 'OTC transactions' },
    { label: 'Inne (rynek niepubliczny)', value: 'Inne (rynek niepubliczny)' },
    { label: 'Rynek amerykański', value: 'Rynek amerykański' },
    { label: 'Giełda papierów wartościowych w Zagrzebiu', value: 'Giełda papierów wartościowych w Zagrzebiu' },
    { label: 'Euronext', value: 'Euronext' },
    { label: 'Deutsche Börse AG', value: 'Deutsche Börse AG' },
    { label: 'Börse München', value: 'Börse München' },
    { label: 'Börse Duesseldorf - QUOTRIX', value: 'Börse Duesseldorf - QUOTRIX' },
  ];

  assetTypeOptions: any[] = [
    { label: 'Obligacje korporacyjne niewyemitowane przez instytucje finansowe - opatrzone ratingiem na poziomie inwestycyjnym', value: 'Obligacje korporacyjne' },
    { label: 'Obligacje korporacyjne niewyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym', value: 'Obligacje korporacyjne niewyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym' },
    { label: 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - opatrzone ratingiem na poziomie inwestycyjnym', value: 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - opatrzone ratingiem na poziomie inwestycyjnym' },
    { label: 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym', value: 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym' },
    { label: 'Inwestycje w przedsiębiorstwa zbiorowego inwestowania prowadzone/zarządzane przez zarządzającego alternatywnymi funduszami inwestycyjnymi - Fundusze rynku pieniężnego i przedsiębiorstwa zbiorowego inwestowania w zarządzanie płynnością', value: 'CIU_OAM_MMFC' },
    { label: 'Inwestycje w przedsiębiorstwa zbiorowego inwestowania prowadzone/zarządzane przez zarządzającego alternatywnymi funduszami inwestycyjnymi - Fundusze indeksowe ETF', value: 'CIU_OAM_AETF' },
    { label: 'Inwestycje w przedsiębiorstwa zbiorowego inwestowania prowadzone/zarządzane przez zarządzającego alternatywnymi funduszami inwestycyjnymi - Inne przedsiębiorstwa zbiorowego inwestowania', value: 'CIU_OAM_OTHR' },
    { label: 'Inwestycje w przedsiębiorstwa zbiorowego inwestowania nieprowadzone/niezarządzane przez zarządzającego alternatywnymi funduszami inwestycyjnymi - Fundusze rynku pieniężnego i przedsiębiorstwa zbiorowego inwestowania w zarządzanie płynności', value: 'CIU_NAM_MMFC' },
    { label: 'Inwestycje w przedsiębiorstwa zbiorowego inwestowania nieprowadzone/niezarządzane przez zarządzającego alternatywnymi funduszami inwestycyjnymi - Fundusze indeksowe ETF', value: 'CIU_NAM_AETF' },
    { label: 'Inwestowanie w tytuły uczestnictwa w przedsiębiorstwach zbiorowego inwestowania nie zarządzanych przez zarządzającego alternatywnymi funduszami inwestycyjnymi - Inne przedsiębiorstwa zbiorowego inwestowania', value: 'CIU_NAM_OTHR' },
    { label: 'Metale szlachetne/Złoto', value: 'DER_CTY_PMGD' },
    { label: 'Instrumenty pochodne na akcje związane z instytucjami finansowymi', value: 'DER_EQD_FINI' },
    { label: 'Inne instrumenty pochodne na akcje', value: 'DER_EQD_OTHD' },
    { label: 'Jednopodmiotowy swap finansowego ryzyka kredytowego', value: 'DER_CDS_SNFI' },
    { label: 'Jednopodmiotowy swap ryzyka kredytowego z tytułu długu państwowego', value: 'DER_CDS_SNSO' },
    { label: 'Inny jednopodmiotowy swap ryzyka kredytowego', value: 'DER_CDS_SNOT' },
    { label: 'Indeksowany swap ryzyka kredytowego', value: 'DER_CDS_INDX' },
    { label: 'Egzotyczne (uwzględniając transzę ryzyka kredytowego)', value: 'DER_CDS_EXOT' },
    { label: 'Inne swapy ryzyka kredytowego', value: 'DER_CDS_OTHR' },
    { label: 'Waluta obca (do celów inwestycyjnych)', value: 'DER_FEX_INVT' },
    { label: 'Waluta obca (do celów ograniczenia ryzyka inwestycyjnego)', value: 'DER_FEX_HEDG' },
    { label: 'Obligacje skarbowe', value: 'Obligacje skarbowe' },
    { label: 'Weksle', value: 'Weksle' },
    { label: 'Inne', value: 'Inne' },
  ]

  tableAssetItems: Array<any> = [];

  tableLiabilitiesItems: Array<any> = [];

  tableAdditionalItems: Array<any> = [];

  getAssetTypeLabel(type: string) {
    const item = this.assetTypeOptions?.find((el: SelectOption<string>) => el?.value === type)

    return item?.label || type
  }

  getAssetMarketLabel(market: string) {
    const item = this.marketOptions?.find((el: SelectOption<string>) => el?.value === market)

    return item?.label || market
  }

  getBalanceRowFromPreviousYear(code: string) {
    return this.lastCompletedReportBalanceFromPreviousYear?.assets?.find((row: any) => row.code === code) || null
  }

  shouldImportButtonBeVisible(code: string) {
    if (!this.lastCompletedReportBalanceFromPreviousYear) {
      return false
    }

    const balanceRow = this.getBalanceRowFromPreviousYear(code)
    
    if (balanceRow?.details?.length) {
      return true
    }

    return false
  }

  importDetailsFromPreviousYear(asset: any) {
    if (!this.isDisabled && this.shouldImportButtonBeVisible) {
      const balanceRow = this.getBalanceRowFromPreviousYear(asset?.code)

      if (balanceRow?.details?.length) {
        const details: any[] = _.cloneDeep(balanceRow?.details?.map((el: any) => {
          el.value = null
          return el
        }))
        this.$nextTick(() => {
          asset.details = details
          this.reRenderDetails++
        })
      }
    }
  }
  
  async uploadXmlFile(files: any[]) {
    this.$emit('setLoading', true);
    this.xml = null

    if (files.length) {
      if (files.length == 1) {
        const file = files[0];
        const formData = new FormData();
        const fileType = file.name.split('.').pop()
        formData.append('file', file);
        const xadesCheck = fileType.toLowerCase() != 'xades'
        if (file.type !== "text/xml" && xadesCheck) {
          this.$emit('setLoading', false);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: 'Wrong file format'
          });
        } else {
          try {
            const xmlFile = await this.$store.dispatch('regReporting/uploadXmlFile', { file, apiQuery: '/regulatory-reporting/sf-report-xml/upload' });
            this.xml = await this.getXmlReportFile(xmlFile?.id);

            let reportType: string = null
            const checkTypes: any[] = [
              { tag: 'JednostkaInnaWTys', multiplier: 1000 },
              { tag: 'JednostkaInna', multiplier: 1 },
              { tag: 'ASIWTys', multiplier: 1000 },
              { tag: 'ASI', multiplier: 1 },
            ]

            let hasTypeError: boolean = true
            checkTypes?.forEach((checkType: any) => {
              const foundTag = this.xmlDoc?.querySelector(checkType?.tag)

              if (foundTag) {
                reportType = checkType?.tag
                hasTypeError = false
              }
            })

            if (hasTypeError) {
              throw new Error('wrongXml')
            }

            const multiplier = checkTypes?.find((el: any) => el.tag === reportType)?.multiplier

            const mapping: any = [
              { table: 'assets', code: 'A. I.', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_I > KwotaA' },
              { table: 'assets', code: 'A. II.', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_II > KwotaA' },
              { table: 'assets', code: 'A. III.', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_III > KwotaA' },
              { table: 'assets', code: 'A. IV. 1.', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_1 > KwotaA' },
              { table: 'assets', code: 'A. IV. 2.', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_2 > KwotaA' },
              { table: 'assets', code: 'A. IV. 4.', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_4 > KwotaA' },
              { table: 'assets', code: 'A. V.', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_V > KwotaA' },
              { table: 'assets', code: 'B. I.', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_I > KwotaA' },
              { table: 'assets', code: 'B. II.', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_II > KwotaA' },
              { table: 'assets', code: 'B. III. 2.', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_2 > KwotaA' },
              { table: 'assets', code: 'B. IV.', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_IV > KwotaA' },
              { table: 'assets', code: 'C.', path: 'Bilans > Aktywa > Aktywa_C > KwotaA' },
              { table: 'assets', code: 'D.', path: 'Bilans > Aktywa > Aktywa_D > KwotaA' },
              { table: 'liabilities', code: 'A. Kapitał (fundusz) własny', path: 'Bilans > Pasywa > Pasywa_A > KwotaA' },
              { table: 'liabilities', code: 'B. Zobowiązania i rezerwy na zobowiązania', path: 'Bilans > Pasywa > Pasywa_B > KwotaA' },
              { table: 'additionals', code: 'Kapitał (fundusz) podstawowy', path: 'Bilans > Pasywa > Pasywa_A > Pasywa_A_I > KwotaA' },
            ]

            const mappingWithLookups: any = [
              {
                table: 'assets', 
                parentTag: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > KwotaA',
                lookups: [
                  {
                    table: 'assets', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_A > KwotaA', items: [
                      {
                        table: 'assets', code: 'A. IV. 3. a. aa', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_A > Aktywa_A_IV_3_A_1 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. a. ab', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_A > Aktywa_A_IV_3_A_2 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. a. ac', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_A > Aktywa_A_IV_3_A_3 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. a. ad', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_A > Aktywa_A_IV_3_A_4 > KwotaA'
                      },
                    ]
                  },
                  {
                    table: 'assets', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_B > KwotaA', items: [
                      {
                        table: 'assets', code: 'A. IV. 3. b. aa', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_B > Aktywa_A_IV_3_B_1 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. b. ab', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_B > Aktywa_A_IV_3_B_2 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. b. ac', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_B > Aktywa_A_IV_3_B_3 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. b. ad', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_B > Aktywa_A_IV_3_B_4 > KwotaA'
                      },
                    ]
                  },
                  {
                    table: 'assets', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_C > KwotaA', items: [
                      {
                        table: 'assets', code: 'A. IV. 3. c. aa', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_C > Aktywa_A_IV_3_C_1 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. c. ab', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_C > Aktywa_A_IV_3_C_2 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. c. ac', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_C > Aktywa_A_IV_3_C_3 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'A. IV. 3. c. ad', path: 'Bilans > Aktywa > Aktywa_A > Aktywa_A_IV > Aktywa_A_IV_3 > Aktywa_A_IV_3_C > Aktywa_A_IV_3_C_4 > KwotaA'
                      },
                    ]
                  }
                ],
              },
              {
                table: 'assets',
                parentTag: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > KwotaA',
                lookups: [
                  {
                    table: 'assets', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_A > KwotaA', items: [
                      {
                        table: 'assets', code: 'B. III. 1. a. aa', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_A > Aktywa_B_III_1_A_1 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. a. ab', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_A > Aktywa_B_III_1_A_2 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. a. ac', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_A > Aktywa_B_III_1_A_3 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. a. ad', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_A > Aktywa_B_III_1_A_4 > KwotaA'
                      },
                    ]
                  },
                  {
                    table: 'assets', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_B > KwotaA', items: [
                      {
                        table: 'assets', code: 'B. III. 1. b. aa', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_B > Aktywa_B_III_1_B_1 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. b. ab', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_B > Aktywa_B_III_1_B_2 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. b. ac', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_B > Aktywa_B_III_1_B_3 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. b. ad', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_B > Aktywa_B_III_1_B_4 > KwotaA'
                      },
                    ]
                  },
                  {
                    table: 'assets', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_C > KwotaA', items: [
                      {
                        table: 'assets', code: 'B. III. 1. c. aa', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_C > Aktywa_B_III_1_C_1 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. c. ab', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_C > Aktywa_B_III_1_C_2 > KwotaA'
                      },
                      {
                        table: 'assets', code: 'B. III. 1. c. ac', path: 'Bilans > Aktywa > Aktywa_B > Aktywa_B_III > Aktywa_B_III_1 > Aktywa_B_III_1_C > Aktywa_B_III_1_C_3 > KwotaA'
                      },
                    ]
                  }
                ],
              }
            ]

            let nip: any = this.xmlDoc.querySelector('WprowadzenieDoSprawozdaniaFinansowego > P_1 > P_1D')
            let year: any = this.xmlDoc.querySelector('Naglowek > OkresDo')

            if (!nip || !year) {
              throw new Error('wrongXml')
            }

            nip = nip?.textContent || ''
            year = moment(year?.textContent).year() || ''

            // if (year != this.newReportFormData.reportYear?.value) {
            //   throw new Error('wrongYear')
            // }

            // check nip
            const leData = await this.$store.dispatch('genprox/getLeContextData', this.selectedASI?.id)
            if (leData?.taxNumber != nip) {
              throw new Error('wrongNip')
            }

            mapping.forEach((row: any) => {
              const foundTag: any = this.xmlDoc.querySelector(row.path)
              if (foundTag) {
                this.$store.commit('regReporting/setBalanceRowValue', { table: row.table, code: row?.code, value: math.number(math.multiply(math.bignumber(foundTag?.textContent || 0), math.bignumber(multiplier))) })
              } else {
                this.$store.commit('regReporting/setBalanceRowValue', { table: row.table, code: row?.code, value: 0 })
              }
            })

            mappingWithLookups.forEach((mapping: any) => {
              let parentTotal: any = this.xmlDoc.querySelector(mapping?.parentTag)?.textContent || 0
              
              // use multiplier
              parentTotal = math.number(math.multiply(math.bignumber(parentTotal), math.bignumber(multiplier)))

              if (!parentTotal) {
                const rows: any[] = mapping?.lookups?.map((lookup: any) => lookup?.items || [])?.flat()
                rows?.forEach((row: any) => {
                  this.$store.commit('regReporting/setBalanceRowValue', { table: row?.table, code: row?.code, value: 0 })
                })
              } else {
                let totalValue: any = 0

                mapping?.lookups?.forEach((lookup: any) => {

                  // use multiplier
                  const foundValue = math.multiply(math.bignumber(this.xmlDoc.querySelector(lookup?.path)?.textContent || 0), math.bignumber(multiplier))
                  // eslint-disable-next-line
                  totalValue = math.number(math.add(math.bignumber(totalValue), math.bignumber(foundValue)))

                  if (foundValue) {
                    let innerTotalValue: any = 0
                    lookup?.items?.forEach((row: any) => {

                      // use multiplier
                      const value: any = math.number(math.multiply(math.bignumber(this.xmlDoc.querySelector(row?.path)?.textContent || 0), math.bignumber(multiplier)))
                      innerTotalValue = math.number(math.add(math.bignumber(innerTotalValue), math.bignumber(value)))

                      this.$store.commit('regReporting/setBalanceRowValue', { table: row?.table, code: row?.code, value })
                    })


                    const innerDifference: any = math.number(math.subtract(foundValue, math.bignumber(innerTotalValue)))
                    
                    if (innerDifference !== 0) {
                      const detailToApply = lookup?.items[0]

                      // use multiplier
                      const detailToApplyCurrentValue: any = math.number(math.multiply(math.bignumber(this.xmlDoc.querySelector(detailToApply?.path)?.textContent || 0), math.bignumber(multiplier)))

                      this.$store.commit('regReporting/setBalanceRowValue', {
                        table: detailToApply?.table,
                        code: detailToApply?.code,
                        value: math.number(math.bignumber(math.add(math.bignumber(detailToApplyCurrentValue), math.bignumber(innerDifference))))
                      })
                    }
                  } else {
                    lookup?.items?.forEach((row: any) => {
                      this.$store.commit('regReporting/setBalanceRowValue', { table: row?.table, code: row?.code, value: 0 })
                    })
                  }
                })

                const difference: any = math.number(math.subtract(math.bignumber(parentTotal), math.bignumber(totalValue)))

                if (difference !== 0) {
                  const detailToApply = mapping?.lookups[0]?.items[0]
                  
                  // use multiplier
                  const detailToApplyCurrentValue: any = math.number(math.multiply(math.bignumber(this.xmlDoc.querySelector(detailToApply?.path)?.textContent || 0), math.bignumber(multiplier)))

                  this.$store.commit('regReporting/setBalanceRowValue', { 
                    table: detailToApply?.table, 
                    code: detailToApply?.code, 
                    value: math.number(math.bignumber(math.add(math.bignumber(detailToApplyCurrentValue), math.bignumber(difference))))
                  })
                }
              }
            })
            
            const data: CreateRegRepDTO = {
              fundIds: this.newReportFormData.selectedASI.map((el: any) => el.id),
              year: Number(this.newReportFormData.reportYear.value),
              correctionNumber: !this.newReportFormData.reportType.isFirstReport ? this.newReportFormData.reportType.value : 0,
              draft: true,
              data: JSON.stringify(this.newReportFormData),
            }

            let reportId: string | null = this.reportId ? this.reportId : null

            if (!this.reportId) {
              reportId = await this.$store.dispatch('regReporting/createReporting', data);
            } else {
              await this.$store.dispatch('regReporting/updateReporting', { data, reportId })
            }

            const reportData = await this.$store.dispatch('regReporting/getReport', reportId)
            const selectedReportData = reportData.funds.find((el: any) => el.id === this.selectedASI?.id);
            const importedFiles = selectedReportData?.inputFiles?.filter((el: any) => el.category === 'Financial Statement XML')

            // add report to input files
            const filePayload: UploadFileToReportDTO = {
              payload: {
                fileId: xmlFile?.id,
                fundId: this.selectedASI?.id,
                category: 'Financial Statement XML'
              },
              reportId,
            }
            
            await this.$store.dispatch('regReporting/uploadReportFile', filePayload)

            if (importedFiles?.length) {
                // if has then delete them
                for (const file of importedFiles) {
                  // deleteReportInputFile
                  await this.$store.dispatch('regReporting/deleteReportInputFile', { reportId, fileId: file?.id})
                }
            }

            this.loadTableItems();
            this.validateAdditionals();

            this.$notify({
              duration: 3000,
              type: 'success',
              title: 'Success',
              text: 'Xml report imported successfully.'
            })
            
          } catch (e: any) {
            let message: string = 'Something went wrong, please try again later.'
            switch(e?.message) {
              case 'wrongXml':
                message = 'Wrong xml file format.'
                break;
              case 'wrongYear':
                message = 'Wrong reporting year.'
                break;
              case 'wrongNip':
                message = 'Wrong tax number.'
                break;
            }

            this.$notify({
              duration: 2500,
              type: 'error',
              title: 'Error',
              text: message
            });
          }
        }
      } else {
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Multiple files',
          text: 'You can select only single file to upload.'
        });
      }
    }

    this.$emit('setLoading', false);
  }

  get xmlDoc() {
    const parser = new DOMParser();

    return parser.parseFromString(this.xml, "text/xml");
  }

  async getXmlReportFile(id: string | string[]) {
    const data = await this.$store.dispatch('genprox/getXmlFile', id);
    
    return data;
  }

  pasteHandler(e: any, type: 'name' | 'value', item: any, index: number) {
    const clipboardData = e?.clipboardData || (window as any)?.clipboardData;
    const pastedText = clipboardData?.getData('Text') || clipboardData?.getData('text/plain');

    if (!pastedText && pastedText?.length) {
      return;
    }

    let rows: any = pastedText.split(/\r?\n/)

    const formattedRows = rows?.filter((el: any) => !!(el)).map((el: any) => {
      const values = el.split('\t')
      return values
    }) || []

    if (!(formattedRows?.length === 1 && formattedRows[0]?.length === 1)) {
      e.preventDefault()
    } else {
      return true
    }

    if (formattedRows[0]?.length > 2) {
      const colAmount = formattedRows[0]?.length
      const allSame = formattedRows.every((row: any) => row.length === colAmount)
      let indexesToRemove = []

      if (allSame) {
        for (let i = 0; i < colAmount; i++) {
          const data = formattedRows.map((el: any) => el[i])
          const removeIndex = data.every((el: any) => !el)
          if (removeIndex) {
            indexesToRemove.push(i)
          }
        }
      }

      indexesToRemove = indexesToRemove.sort((a: any, b: any) => b - a)

      indexesToRemove.forEach((index: number) => {
        formattedRows.forEach((row: any) => {
          row.splice(index, 1)
        })
      })
    }

    const valid = formattedRows?.every((el: any) => el?.length === 1) || formattedRows?.every((el: any) => el?.length === 2)
    const isSingular = formattedRows?.every((el: any) => el?.length === 1)

    if (valid) {
      const toCreate = formattedRows?.length - (item?.details?.length - index)
      for (let i = 0; i < toCreate; i++) {
        item.details.push({
          id: item.details?.length,
          name: '',
          country: 'Europa (EOG)',
          market: 'Inne (rynek niepubliczny)',
          type: '',
          isiiCode: '',
          subAssetType: item.subAssetType,
          value: null,
        });
      }

      const alreadyCreated = item?.details?.length - index
      
      this.$nextTick(() => {
        for (let i = 0; i < alreadyCreated; i++) {
          if (isSingular) {
            if (item.details[index + i] && formattedRows[i]) {
              item.details[index + i][type] = type === 'value' ? this.applyNumberFormat(formattedRows[i][0]) : formattedRows[i][0]
            }
          } else {
            if (item.details[index + i] && formattedRows[i]?.length === 2) {
              item.details[index + i].name = formattedRows[i][0]
              item.details[index + i].value = this.applyNumberFormat(formattedRows[i][1])
            }
          }
        }
      
        this.reRenderDetails++
      })
    } else {
      this.$notify({
        type: 'error',
        title: 'Data can\'t be pasted',
        text: 'Wrong text format',
        duration: 1000,
      })
    }
  }

  applyNumberFormat(value: any) {
    return Number(String(value).replaceAll(' ', '').replaceAll('\u00A0', '').replaceAll(',', '.').replace(/[^0-9.,]+/, '') || 0)
  }

  refreshDetails() {
    this.tableAssetItems?.forEach((el: any) => {
      if (el.amount) {
        el._showDetails = true;
        if (!el.details) {
          el.details = [{
            id: 0,
            name: '',
            country: 'Europa (EOG)',
            market: 'Inne (rynek niepubliczny)',
            type: '',
            isiiCode: '',
            subAssetType: el.subAssetType,
            value: null,
          }];
        }
      } else {
        el._showDetails = false;
        el.details = []
      }
    })
  }

  resetDetails(item: any) {
    if (!item?.details || item?.details?.length === 0) {
      item.details = [{
        id: 0,
        name: '',
        country: 'Europa (EOG)',
        market: 'Inne (rynek niepubliczny)',
        type: '',
        isiiCode: '',
        subAssetType: item.subAssetType,
        value: null,
        code: item?.code
      }];
    }

    if (!item?.amount) {
      item.details = []
    }
  }

  countDecimals(value: number) {
    if (Math.floor(value) === value) return 0;
    return value?.toString().split(".")[1]?.length || 0;
  }

  formatNumber(value: number) {
    const length = this.countDecimals(Number(value));
    return this.$options.filters.numberFormat(value, length > 1 ? length : 2, true);
  }

  getEndReportDate(item: any) {
    const { value: reportDate } = this.newReportFormData.reportYear;

    if (item) {
      return item;
    } else {
      return `${reportDate}-12-31`;
    }
  }

  get newReportFormData() {
    return this.$store.getters['regReporting/getNewReportFormData'];
  }

  get currentStep() {
    return this.newReportFormData.currentStep;
  }
  
  get isDetailsStep() {
    return this.currentStep === 6 ? true : false;
  }

  get assetsTotal() {
    let total = 0;
    this.tableAssetItems?.forEach((el: any) => {
      total = math.number(math.add(math.bignumber(total), math.bignumber(el.amount)));
    });

    return total;
  }

  validateAdditionals() {
    const entireCapital = this.tableAdditionalItems[0].amount;
    const capital = this.tableAdditionalItems[1].amount;

    let error = capital <= entireCapital ? false : true;

    if(entireCapital && !error) {
      error = capital > 0 ? false : true;
    }

    this.additionalsError = error;
    this.$emit('setNextStepBtnDisabled', error);
  }

  detailsAmount(item: any) {
    let total = 0;

    item.details.forEach((el: any) => {
      total = math.number(math.add(math.bignumber(total), math.bignumber(el?.value || 0)));
    })

    return total;
  }

  detailsTotal(item: any) {
    let total = 0;

    this.tableAssetItems?.filter((el: any) => el.code.includes(item.code)).forEach((el: any) => {
      if(this.isDetailsStep) {
        if(el.includeInDetails) {
          total = math.number(math.add(math.bignumber(total), math.bignumber(el?.amount || 0)));
        }
      } else {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el?.amount || 0)));
      }
    })

    return total;
  }

  get hasImportOption() {
    return !(this.isDisabled)
  }

  get liabilitiesTotal() {
    let total = 0;
    this.tableLiabilitiesItems.forEach((el: any) => {
      total = math.number(math.add(math.bignumber(total), math.bignumber(el?.amount || 0)));
    });

    return total;
  }

  get assetsDifference() {
    return math.number(math.subtract(math.bignumber(this.liabilitiesTotal || 0), math.bignumber(this.assetsTotal || 0)))
  }
  
  get liabilitiesDifference() {
    return math.number(math.subtract(math.bignumber(this.assetsTotal || 0), math.bignumber(this.liabilitiesTotal || 0)))
  }

  get rowsWithValues() {
    return this.tableAssetItems?.filter((el: any) => {
      return el.amount && el.includeInDetails ? true : false;
    }).map((el: any) => {
      return el.code;
    })
  }

  isCodeHavingChildren(checkedCode: string) {
    let isCodeHavingChildren = false;

    this.rowsWithValues.forEach((code: string) => {
      if (code.includes(checkedCode)) {
        isCodeHavingChildren = true;
      }
    });

    return isCodeHavingChildren;
  }

  copyDetails(asset: any) {
    const hasType = !!(this.assetTypeOptionsEnabledFor?.includes(asset?.code))

    const headers = ['NAZWA AKTYWÓW', 'WARTOŚĆ', 'REGION', 'RYNEK', 'KOD ISIN']

    if (hasType) {
      headers.splice(3, 0, 'RODZAJ')
    }

    const detailRows: any[] = []

    asset?.details.forEach((detail: any) => {
      const detailArray = [detail?.name, this.$options.filters.numberFormat(detail?.value, 2), detail?.country, detail?.market, detail?.isiiCode]

      if (hasType) {
        detailArray.splice(3, 0, detail?.type)
      }

      detailRows.push(detailArray)
    })

    const detailsArray = [
      headers,
      ...detailRows
    ]

    this.$options.filters.copy2DToClipboard(detailsArray)
  }

  changeCountryOption() {
    this.$nextTick(() => {
      this.reRenderDetails++;
      this.refreshTable();
      this.validateDetails();
    })
  }

  changeTypeOption(detail: any, value: any) {
    this.$set(detail, 'type', value || '')
    this.$nextTick(() => {
      this.reRenderDetails++;
      this.refreshTable();
      this.validateDetails();
    })
  }

  changeMarketOption(detail: any) {
    if (this.isIsiiCodeDisabled(detail)) {
      this.$set(detail, 'isiiCode', null);
    }

    this.$nextTick(() => {
      this.reRenderDetails++;
      this.refreshTable();
      this.validateDetails();
    })
  }

  handleInput(e: any, detail: any) {
    this.$set(detail, 'isiiCode', e.target.value);
    this.$nextTick(() => {
      this.refreshTable(true);
      this.validateDetails();
    })
  }

  handleCurrencyInput(detail: any) {
    this.$set(detail, 'value', Number(detail.value));
    this.$nextTick(() => {
      this.refreshTable();
      this.validateDetails();
    })
  }

  isIsiiCodeDisabled(detail: any) {
    return (!detail?.market || detail?.market === 'Inne (rynek niepubliczny)' || detail?.market === 'OTC transactions') ? true : false;
  }

  get isDetailEditingEnabled() {
    return (rowDataItem: any) => {
      if (this.isDisabled) {
        return false;
      }

      let total = 0;
      const entireValue = Number(rowDataItem.amount);

      rowDataItem.details.forEach((el: any) => {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el?.value || 0)));
      });
  
      return total >= entireValue ? false : true;
    }
  }

  get filteredTableAssetItems() {
    this.tableAssetItems = this.tableAssetItems?.map((el: any) => {
      if(this.currentStep !== 6) {
        el._showDetails = false;
      }
      return el;
    })
    if (this.isDetailsStep) {
      return this.tableAssetItems?.filter((el: any) => {
        if(el.nest === 4 && el.includeInDetails) {
          return el.amount ? true : false;
        }

        return this.isCodeHavingChildren(el.code) ? el.includeInDetails : false;
      });
    }

    return this.tableAssetItems;
  }

  get filteredTableAssets() {
    return this.filteredTableAssetItems?.filter((el: any) => this.rowsWithValues.includes(el.code))
  }

  get isReportForm() {
    return this.currentStep === 5 ? true : false;
  }

  removeDetail(item: any, detailId: number) {
    if (item.details?.length > 1 && !this.isDisabled) {
      const indexToRemove = item.details.findIndex((el: any) => el.id === detailId);
      item.details.splice(indexToRemove, 1);
      this.$nextTick(() => {
        this.reRenderDetails++;
        this.refreshTable();
        this.validateDetails();
      })
    }
  }

  refreshTable(reRenderTotals: boolean = false) {
    this.$root.$emit('bv::refresh::table');

    if (reRenderTotals) {
      this.reRenderTotals++
    }
  }

  addNewDetail(item: any, currentIndex: number) {
    if (this.isDisabled) {
      return;
    }

    const currentIdIndex = Math.max(...item.details.map((i: any) => i.id));
    item.details.splice(currentIndex + 1, 0, {
      id: currentIdIndex + 1,
      name: '',
      country: 'Europa (EOG)',
      market: 'Inne (rynek niepubliczny)',
      type: '',
      isiiCode: '',
      subAssetType: item.subAssetType,
      value: null,
      code: item?.code
    });
    this.$nextTick(() => {
      this.reRenderDetails++;
      this.refreshTable();
      this.validateDetails();
    })
  }

  addNewDetailLegacy(rowData: any) {
    const currentIdIndex = Math.max(...rowData.item.details.map((i: any) => i.id));
    rowData.item.details.push({
      id: currentIdIndex + 1,
      name: '',
      country: 'Europa (EOG)',
      market: 'Inne (rynek niepubliczny)',
      type: '',
      isiiCode: '',
      subAssetType: rowData.item.subAssetType,
      value: null,
    });
    this.refreshTable();
  }

  showTooltip(tooltipId: string, row: any) {
    if(this.isDetailsStep) return;
    if(row.item.highlight) return;
    this.$root.$emit('bv::show::tooltip', tooltipId);
  }
  
  hideTooltip(tooltipId: string, row: any) {
    if(this.isDetailsStep) return;
    if(row.item.highlight) return;
    this.$root.$emit('bv::hide::tooltip', tooltipId);
  }
  
  focusInput(index: number, tableSelector: string) {
    if(this.isDetailsStep) return;
    const selector = `${tableSelector} table tr:not(.highlighted):nth-of-type(${index + 1}) .sygni-plain-input input`;
    if (this.$el.querySelector(selector)) {
      (this.$el.querySelector(selector) as HTMLInputElement).focus();
    }
  }

  rowClass(item: any, type: any) {
    let className = item.highlight ? 'highlighted ' : '';
    if (!item || type !== 'row') return
    if (item.nest === 0) {
      className += 'nest-0'
    } else if (item.nest === 1) {
      className += 'nest-1'
    } else if (item.nest === 2) {
      className += 'nest-2'
    } else if (item.nest === 3) {
      className += 'nest-3'
    } else if (item.nest === 4) {
      className += 'nest-4'
    } else {
      className += 'nest-5'
    }

    return className;
  }

  mountDetails() {
    this.tableAssetItems?.forEach((el: any) => {
      if (el.amount && this.currentStep == 6) {
        el._showDetails = true;
        if(!el.details) {
          el.details = [{
            id: 0,
            name: '',
            country: 'Europa (EOG)',
            market: 'Inne (rynek niepubliczny)',
            type: '',
            isiiCode: '',
            subAssetType: el.subAssetType,
            value: null,
          }];
        }
      } else {
        el._showDetails = false;
        el.details = []
      }
    })
  }

  get selectedASI() {
    return this.$store.getters['regReporting/getSelectedASI'];
  }

  getAssetTitle(code: string) {
    const splittedCode = code?.split(' ')

    let currentCode = ""
    let assetTitle = ""
    if (splittedCode?.length) {
      splittedCode.forEach((c: string) => {
        currentCode += currentCode ? ` ${c}` : c
        const detail = this.filteredTableAssetItems.find((el: any) => el.code === currentCode)

        if (detail) {
          assetTitle += `${detail?.segment} /`
        } else {
          assetTitle += `${c} /`
        }
      })
    }

    return assetTitle?.substring(0, assetTitle?.length - 2) || code
  }

  created() {
    this.loadTableItems();
    this.validateAdditionals();
  }

  loadTableItems() {
    if(this.selectedASI.balanceData !== null) {
      this.tableAssetItems = _.cloneDeep(this.selectedASI.balanceData.assets);
      this.tableLiabilitiesItems = _.cloneDeep(this.selectedASI.balanceData.liabilities);
      this.tableAdditionalItems = _.cloneDeep(this.selectedASI.balanceData.additionals);
      return;
    }

    if(!this.tableAssetItems?.length) {
      this.tableAssetItems = _.cloneDeep(this.$store.getters['regReporting/getAssetsSchema']);
    }

    if(!this.tableLiabilitiesItems?.length) {
      this.tableLiabilitiesItems = _.cloneDeep(this.$store.getters['regReporting/getLiabilitiesSchema']);
    }

    if(!this.tableAdditionalItems?.length) {
      this.tableAdditionalItems = _.cloneDeep(this.$store.getters['regReporting/getAdditionalSchema']);
    }
  }

  clearTableData() {
    if(this.selectedASI.balanceData === null) {
      this.tableAssetItems = _.cloneDeep(this.$store.getters['regReporting/getAssetsSchema']);
      this.tableLiabilitiesItems = _.cloneDeep(this.$store.getters['regReporting/getLiabilitiesSchema']);
      this.tableAdditionalItems = _.cloneDeep(this.$store.getters['regReporting/getAdditionalSchema']);
    }
  }

  get sortedDetails() {
    if (this.filteredTableAssetItems?.length) {
      let details: any = [];
      
      this.filteredTableAssetItems?.forEach((row: any) => {
        if (row.details && row.includeInDetails) {
          details = details.concat(row.details);
        }
      });

      return details;
    }

    return [];
  }

  get selectedASIName() {
    return this.$store.getters['regReporting/getSelectedASIName'];
  }

  validateDetails() {
    let hasErrors = false;

    if(this.sortedDetails?.length) {
      this.sortedDetails.forEach((detail: any) => {

        if (!detail?.country || (this.assetTypeOptionsEnabledFor?.includes(detail?.code) && !detail?.type)) {
          hasErrors = true
        }

        if(detail?.market === 'Rynek giełdowy' || detail?.market === 'NewConnect' || detail?.market === 'Rynek amerykański' || detail?.market === 'Giełda papierów wartościowych w Zagrzebiu' || detail?.market === 'Euronext') {
          if(detail.isiiCode?.length !== 12) {
            hasErrors = true;
          }
        }
      })
    }

    this.$emit('setNextStepBtnDisabled', hasErrors);
  }

  @Watch('assetsTotal') onAssetsTotal() {
    this.$emit('updateTotals', {
      assetsTotal: this.assetsTotal,
      liabilitiesTotal: this.liabilitiesTotal,
    });
  }
  
  @Watch('liabilitiesTotal') onLiabilitiesTotal() {
    this.$emit('updateTotals', {
      assetsTotal: this.assetsTotal,
      liabilitiesTotal: this.liabilitiesTotal,
    });  
  }

  @Watch('tableAssetItems', { deep: true }) onTableAssetItemsUpdate() {
    this.$forceUpdate();
    if(this.currentStep == 6) {
      this.validateDetails();
    }
    this.$emit('updateBalanceAssets', this.tableAssetItems);
  }
  
  @Watch('tableLiabilitiesItems', {deep: true}) onTableLiabilitiesItems() {
    this.$forceUpdate();
    this.$emit('updateBalanceLiabilities', this.tableLiabilitiesItems);
  }

  @Watch('tableAdditionalItems', {deep: true}) onTableAdditionalItems() {
    this.$forceUpdate();
    this.$emit('updateBalanceAdditionals', this.tableAdditionalItems);
  }

  @Watch('currentStep') onCurrentStepChange() {
    this.mountDetails();
    if (this.isReportForm) {
      this.loadTableItems();
      this.validateAdditionals();
    }
  }

  @Watch('selectedASIName') onSelectedASINameChange() {
    this.clearTableData();
  }
}
