


























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import FundsTable from "@/modules/genprox/modules/AIFM/modules/dashboard/components/FundsTable.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import FundManagerCard from "@/modules/genprox/modules/AIFM/components/FundManagerCard.vue";
import ManagementTeam from "@/modules/genprox/modules/AIFM/components/ManagementTeamCard.vue";
import FundInformationCard from "@/modules/genprox/modules/AIFM/components/FundInformationCard.vue";
import ReportingRegulatoryCard
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { mapState } from "vuex";
import RegRepCard from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCard.vue";
import RegRepCardNew from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCardNew.vue";
import { Prop } from "vue-property-decorator";
import { FundManager, MTMember } from "@/modules/genprox/models/User";
import { Report } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import ReportForm from "@/modules/genprox/modules/fund/modules/reg-reporting/containers/report-form/ReportForm.vue";
import _ from 'lodash';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { ReportStatuses } from '@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types';

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    RegRepCard,
    RegRepCardNew,
    SygniRoundedButton,
    ReportingRegulatoryCard,
    FundInformationCard,
    ManagementTeam, FundManagerCard, UserPresentation, FundsTable, SygniContainerTitle, SygniCard, ReportForm
  },
  computed: {
    ...mapState('genprox', {
      managementTeam: (state: any) => state.managementTeam,
      activeUserContextId: (state: any) => state.activeUserData.context?.id
    }), ...mapState('regReporting', {
      fundManagerData: (state: any) => state.fundData,
      reports: (state: any) => state.reports.sort((reportA: Report, reportB: Report) => {
        return reportA.year < reportB.year ? 1 : -1;
      })
    })
  }
})
export default class RegReportingModule extends Vue {
  @Prop() openCardId!: string;
  fundManagerData!: FundManager;
  reRender: number = 0;
  reportId: string | null = null;
  reportStatus: string | null = null;

  managementTeam!: MTMember[];
  reports!: Array<Report>;
  activeUserContextId!: string;
  formReportId: null | string = null;
  formOpened: boolean = false;
  createdReport: number | null = null;
  reRenderReports: number = 0;

  get nameSymbol(): string {
    return this.fundManagerData.name?.slice(0, 1);
  }

  get groupedReports(): any {
    const sortedReports: any[] = [];
    let reports = _(this.reports)?.groupBy('year')?.value();

    // sort years descending
    const keys = Object.keys(reports).sort((a: any, b: any) => b - a);

    if(keys?.length) {
      keys.forEach((key) => {
        if (this.createdReport && Number(key) == this.createdReport) {
          sortedReports.unshift(reports[key]);
        } else {
          sortedReports.push(reports[key]);
        }
      })
    }

    return sortedReports ? sortedReports : [];
  }

  openReportPreview(selectedReport: any) {
    sessionStorage.removeItem('reportData');
    this.$store.commit('regReporting/setNewReportFormData', selectedReport?.data);
    this.$store.commit('regReporting/setCurrentStep', 4)
    this.reportId = selectedReport?.id;
    this.reportStatus = selectedReport?.status;
    this.formOpened = true;
  }

  editDraftReport(selectedReport: any) {
    sessionStorage.removeItem('reportData');
    this.$store.commit('regReporting/setNewReportFormData', selectedReport?.data);
    this.reportId = selectedReport?.id;
    this.reportStatus = ReportStatuses.DRAFT;
    this.formOpened = true;
  }

  openForm() {
    this.reportId = null;
    this.reportStatus = null;
    this.formOpened = true;
  }

  closeForm() {
    sessionStorage.removeItem('reportData');
    this.$store.commit('regReporting/resetNewReportFormData');
    this.formOpened = false;
  }

  addNewReport(): void {
    this.$router.push('reg-reporting/add-report');
  }

  loadReportFormData() {
    if (sessionStorage.getItem('reportData')) {
      this.$store.commit('regReporting/setNewReportFormData', sessionStorage.getItem('reportData'));
      this.formOpened = true;
    }
  }

  async refreshReports(year: number | null) {
    if(year) this.createdReport = year;
    this.$nextTick(async () => {
      await this.$store.dispatch('regReporting/getReports');
      this.reRender++;
      this.closeForm();
    });
  }

  beforeUnloadHandler() {
    if (this.formOpened && !this.reportId) {
      const newReportFormData = this.$store.getters['regReporting/getNewReportFormData'];

      if(newReportFormData.currentStep == 5 || newReportFormData.currentStep == 6) {
        (this.$refs.reportForm as ReportForm).setSelectedASIBalance(false);
      }

      sessionStorage.setItem('reportData', JSON.stringify(newReportFormData));
    }
  }

  created() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
  }

  async mounted() {
    this.reports = [];
    this.$store.dispatch('genprox/getManagementTeam', this.activeUserContextId);
    const fundData: FundManager = await this.$store.dispatch('genprox/getContextData', this.activeUserContextId);
    await this.$store.dispatch('regReporting/getReports');
    this.reRenderReports++
    await this.$store.dispatch('regReporting/getFunds');
    await this.$store.dispatch('regReporting/getOutputReportData');
    this.$store.commit('regReporting/setFundData', fundData);
    this.loadReportFormData();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    if(this.formOpened && this.reportStatus === ReportStatuses.DRAFT) {
      (this.$refs.reportForm as ReportForm).openDraftModal(next);
    } else {
      next();
    }
  }
}

