var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-8 offset-lg-2"
  }, [_c('sygni-container-title', [_vm._v("Krok 2 - Wybierz jednostki do zaraportowania")]), _c('sygni-info-box', [_c('p', [_vm._v("Wybierz ASI, które chcesz zaraportować do KNF (w przypadku WZASI generowane są również dwa raporty). Jeśli składasz raport zerowy, to koniecznie odznacz pole 'Report the data'.")])]), _c('sygni-select', {
    attrs: {
      "value": _vm.formData.selectedZASI,
      "label": 'ZASI',
      "options": _vm.zasiOptions,
      "disabled": "",
      "searchable": ""
    }
  }), _c('sygni-multi-select', {
    attrs: {
      "value": _vm.selectedAsiOptions,
      "clear-on-select": false,
      "close-on-select": false,
      "disabled": _vm.isFund || _vm.isDisabled,
      "display-values": true,
      "label": 'ASI',
      "multiple": true,
      "options": _vm.asiOptions,
      "preserve-search": false
    },
    on: {
      "input": _vm.setSelectedASIs
    }
  }), _vm.newReportFormData.selectedASI.length ? _c('div', {
    staticClass: "step__list-header"
  }, [_c('div', [_vm._v("Name")]), _vm._m(0)]) : _vm._e(), _vm.newReportFormData.selectedASI.length ? _c('ul', {
    staticClass: "step__list"
  }, _vm._l(_vm.newReportFormData.selectedASI, function (item, index) {
    return _c('li', {
      key: "".concat(index).concat(item.name)
    }, [_c('div', [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.name ? "".concat(item.name, " (").concat(item.aifNumber, ")") : _vm.fundData.name) + " ")]), _c('div', [_c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "value": item.reportData,
        "remove-watcher": true,
        "label": '',
        "disabled": _vm.isDisabled
      },
      on: {
        "changedValue": function changedValue($event) {
          return _vm.toggleReportData(item.name);
        }
      }
    })], 1)]);
  }), 0) : _vm._e()], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v("Report "), _c('br'), _vm._v("the data")]);
}]

export { render, staticRenderFns }