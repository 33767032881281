




























































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import ReportingRegulatoryList
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepList.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import RegRepCreatingRaport
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCreatingRaport.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import { Prop, Watch } from "vue-property-decorator";
import Utils, { UploadProgress } from "@/modules/shared/utils/utils";
import { BProgress, BSpinner } from "bootstrap-vue";
import RegRepHiddenCard from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepHiddenCard.vue";
import { BACKEND_BASE_URL } from "@/shared/consts";
import FileUploader from "@/components/FileUploader.vue";
import { AxiosRequestConfig } from "axios";
import RegRepHiddenConfirmedCard
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepHiddenConfirmedCard.vue";
import {
  ReportStatuses,
  // UploadFileToReportDTO
} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types";
import { Fund } from "@/modules/genprox/models/User";
import { Report, ReportFile } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import { UploadedFileInfo } from "@/modules/genprox/models/Shared";
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';

export enum RegRepTableActions {
  // eslint-disable-next-line no-unused-vars
  download = 'DOWNLOAD',
  // eslint-disable-next-line no-unused-vars
  delete = 'DELETE'

}

@Component({
  components: {
    RegRepHiddenConfirmedCard,
    FileUploader,
    ExpandCollapse,
    RegRepCreatingRaport,
    SygniLine,
    RegRepHiddenCard,
    ReportingRegulatoryList,
    SygniRoundedButton, UserPresentation, SygniContainerTitle, SygniCard, SygniSelect, BProgress, BSpinner, SygniInfoBox
  }
})
export default class ReportFileUploader extends Vue {
  readonly reportStatuses = ReportStatuses;
  readonly supportedFileFormats: Array<string> = ['doc', 'docx', 'pdf', 'xlsx', 'xls', 'xml'];

  @Prop() report!: Report;
  @Prop() reports!: Report[];
  @Prop() openCardId!: string;
  @Prop() createdReport: number | null;
  @Prop() isDisabled: boolean;
  reportYear: number | null = null;
  fundManagerData: Fund = {};
  uploadProgress: UploadProgress = { progress: 0 };
  reportGeneratingLoading: boolean = false;
  isOpen: boolean = true;
  isLoadingReport: boolean = false;
  editedReportId: number | null = null;
  selectedVersion: string = '';
  reportStatus: string = 'new'
  reRenderTable: number = 0
  showCategoryGuide: boolean = false

  getFileItems(fund: any) {
    return fund?.files?.filter((el: any) => el.label !== 'Financial Statement XML') || []
  }

  get newReportFormData() {
    return this.$store.getters['regReporting/getNewReportFormData']
  }

  get fundData() {
    return this.$store.getters['regReporting/getFundData']
  }

  get isConfirmed(): boolean {
    return this.selectedReport.status === this.reportStatuses.GENERATED;
  }

  get reportOptions() {
    if (!this.reports) {
      return [];
    }

    return this.reports.map((el: Report) => {
      return { label: this.$options.filters.leadingZeroDigitFormat(el.correctionNumber, 3), value: el.id };
    })
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isFund() {
    return this.activeUserData.context.context === 'fund';
  }

  getFundLogo(logo: any) {
    if (this.isFund) {
      return this.fundData?.logo
    } else {
      return logo
    }
  }

  getFundName(fund: any) {
    if (this.isFund) {
      return this.fundData?.name
    } else {
      return `${fund?.name} (${fund?.aifNumber})`
    }
  }

  get selectedReport() {
    return this.reports?.find((el: Report) => el.id === this.selectedVersion);
  }

  get isReportReadyToCreate(): boolean {
    let isReady = true;
    this.selectedReport?.funds?.forEach(fund => {
      if (fund.inputFiles?.length === 0) {
        isReady = false;
      }
    });
    return isReady;
  }

  get hasPreview() {
    return (this.selectedReport?.data && (this.reportStatus === ReportStatuses.GENERATED || this.reportStatus === ReportStatuses.COMPLETED)) ? true : false;
  }

  editDraftReport() {
    this.$emit('editDraftReport', this.selectedReport);
  }

  @Watch('openCard', { immediate: true }) onOpenCardChange() {
    if (this.selectedReport && this.selectedReport.id === this.openCardId) {
      this.toggleOpen();
    }
  }

  beforeMount() {
    // this.selectVersion();
    this.reportYear = this.createdReport;
  }

  closeOpen() {
    this.isOpen = false;
  }

  selectVersion() {
    this.selectedVersion = this.reports.sort((a, b) => b.correctionNumber - a.correctionNumber)[0]?.id;
  }

  async toggleOpen(): Promise<void> {

    if (!(this.selectedReport?.funds?.length > 0)) {
      this.isLoadingReport = true;
      try {
        const getContextDataRequest = this.$store.dispatch('genprox/getLeContextData', this.selectedReport?.fundManagerId);
        await this.$store.dispatch('regReporting/getReport', this.selectedReport?.id);
        this.fundManagerData = await getContextDataRequest;
      } catch (e) {
        e;
      }
      this.isLoadingReport = false;
    }
    this.isOpen = !this.isOpen;
  }

  async createReport(): Promise<void> {
    try {
      this.reportGeneratingLoading = true;
      await this.$store.dispatch('regReporting/generateReport', this.selectedReport?.id);
      this.$store.commit('regReporting/updateReport', { ...this.selectedReport, ...{ status: ReportStatuses.GENERATED } });
      this.isOpen = false;
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Upload success',
        text: 'Report has been successfully sent'
      });


    } catch (e) {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'An error occurred',
        text: this.$t(e.response.data.label).toString()
      });
    }
    this.reportGeneratingLoading = false;
  }

  setCategory(payload: { category: string, file: any }) {
    this.showCategoryGuide = false
    payload.file.category = payload.category
    this.$emit('updateFiles')
  }

  deleteFile(fund: any, index: number) {
    fund.files?.splice(index, 1)
  }

  async uploadFile(file: File, fundId: string): Promise<void> {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);

    try {
      const info: UploadedFileInfo = await this.$store.dispatch('genprox/uploadFile', { file, config });

      const fileObject: any = { ...info, category: null }

      const fund = this.newReportFormData.selectedASI?.find((el: any) => el?.id === fundId)
      if (fund?.files) {
        fund.files.push(fileObject)
      } else {
        this.$set(fund, 'files', [fileObject])
        // fund.files = [fileObject]
      }

      this.reRenderTable++
      this.$nextTick(() => {
        setTimeout(() => {
          this.showCategoryGuide = true
        }, 500);
        this.$notify({
          duration: 2500,
          type: 'success',
          title: 'Success',
          text: 'File has been successfully added'
        });
      })
    } catch (e) {
      e;
    }
  }

  async addFile(file: File[], fundId: string): Promise<void> {
    if (file.length > 1) {
      for (let i = 0; i < file.length; i++) {
        this.uploadFile(file[i], fundId);
      }
    } else {
      this.uploadFile(file[0], fundId);
    }

    this.uploadProgress.progress = -1;
  }

  downloadFile(file: ReportFile): void {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName);
  }

  isXmlReport(file: { fileName: string; }) {
    if (file?.fileName) {
      const fileFormat = (/[.]/.exec(file.fileName)) ? /[^.]+$/.exec(file.fileName)[0] : undefined
      return fileFormat === 'xml';
    }
    return false;
  }

}
